// import React  from "react";
import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import AppBar from '@mui/material/AppBar';

// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';

// import Typography from '@mui/material/Typography';

// import { styled } from '@mui/material/styles';




// function Navbar() {

  
//     return(
//       <>
//       <Grid className="topNav">
//       <Container>
//          <Grid container >
//             <Grid item sm={12} textAlign="right">
//                 <Typography variant="contained" tele="+91-8910834910">+91-8910834910</Typography>
//                 <Typography variant="contained">+91-8910834910</Typography>
//                 <Button variant="contained" >Request A Quote</Button>
                
//             </Grid>
//         </Grid>
//       </Container>
//       </Grid>

//       <AppBar className="navbar" color="transparent" position="sticky">
//         <Container m={0}>
//          <Grid container p={2}>
//             <Grid item sm={4}>
//               <Link className="navbar-brand" to="/"><img src="../../images/logo.png" alt="" /></Link>
//             </Grid>
//             <Grid item sm={8} textAlign="right" absolute={true} className="">              
//               aaaaaaaaaa
//             </Grid>
//          </Grid> 
//          </Container>      
//        </AppBar>
      


//       </>
//   );
// }

// export default Navbar;


// import * as React from 'react';

import React, { useState } from 'react'

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import Menu from '@mui/material/Menu';


const drawerWidth = 240;
//const navItems = ['Home', 'About', 'Contact'];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [toggle, setToggle] = useState(false)

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };




  const drawer = (



   
    <>
    {/* <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="drawerWrapper"> */}
    <Box  className="drawerWrapper">
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        Mobile
      </Typography>
      <Divider /> */}
      <List>
        <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/" >
              Home
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  component={Link} to="/about">
              About
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/portfolio">
            Our Portfolio
            </ListItemButton>
          </ListItem>



          <ListItem disablePadding  onClick={handleClick}>
            <ListItemButton component={Link}>
           
           Services
            </ListItemButton>
            </ListItem>

{/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Services
      </Button> */}

    
            
            
            
      <List
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/services/brand-designing">
            - Brand Designing
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/services/web-development">
           -  Web Development
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/services/app-developmen">
            - App Development
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/services/digital-marketing">
            - Digital Marketing
          </ListItemButton>
        </ListItem>
      </List>

                

         
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/careers">
            Careers
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} to="#"><a href="/blog" target="_blank" style={{textDecoration: 'none',color: '#333'}}>
            Blog</a>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/contact-us">
            Contact Us
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/hire-us">
            Hire Us
            </ListItemButton>
          </ListItem>
      </List>
      

    </Box>



    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;



  return (
<>



<Grid className="topNav">
      <Container>
          <Grid container >
            <Grid item xs={12} textAlign="right" py={1}  >
              <ul>
                <li><Link to="tel:+918910834910"  ><span class="material-symbols-outlined">
phone_iphone
</span> +91-8910834910</Link></li>
                <li><Link to="tel:+918013561965" ><span class="material-symbols-outlined">
phone_iphone
</span> +91-8013561965</Link></li>
                {/* <li><Link to="/contact-us" >Request A Quote</Link></li> */}
              </ul>              
            </Grid>
       </Grid>
     </Container>
</Grid>

<Grid className="navbar">
      <Container>
          <Grid container  py={3}  >
            <Grid item md={4} xs={8} textAlign="left" >
                <Link className="navbar-brand" to="/"><img src="../../images/logo.png" alt="" /></Link>            
            </Grid>

            {/* <Grid item md={8} xs={4} textAlign="right"  pt={1}  sx={{ display: { xs: 'none', sm: 'block' } }} className="mainNav" ></Grid> */}
            <Grid item md={8} xs={4} textAlign="right"  pt={1}  className="mainNav" >
              <MenuList sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MenuItem component={Link} to="/">Home</MenuItem>
              <MenuItem component={Link} to="/about">About</MenuItem>
              <MenuItem component={Link} to="/portfolio">Portfolio</MenuItem>

              <span className='serviceNav'>
              <MenuItem component={Link} onClick={() => setToggle(!toggle)}>Services</MenuItem>

              {toggle && (
                    <MenuList className='subMenu'>
                      <MenuItem component={Link} to="/services/brand-designing">Brand Designing</MenuItem>
                      <MenuItem component={Link} to="/services/web-development">Web Development</MenuItem>
                      <MenuItem component={Link} to="/services/app-development">App Development</MenuItem>
                      <MenuItem component={Link} to="/services/digital-marketing">Digital Marketing</MenuItem>
                  </MenuList>
                )}

              </span>

              <MenuItem component={Link} to="/careers">Careers</MenuItem>
              <MenuItem><a href="/blog" target="_blank" style={{textDecoration: 'none',color: '#555'}}>Blog</a></MenuItem>
              <MenuItem component={Link} to="/contact-us">Contact Us</MenuItem>
              <MenuItem component={Link} to="/hire-us" className='hire-us'>Hire Us</MenuItem>               
            </MenuList>
            </Grid>


                {/* Humberburg */}
            <Grid item sm={4} xs={4} textAlign="right" absolute={true} className="toogleButton">              
             <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                // sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
             </Grid>


       </Grid>
     </Container>
</Grid>



    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

   


      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          // sx={{
          //   display: { xs: 'block', sm: 'none' },
          //   '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          // }}
          md={{
            display: { sm: 'block', xs: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}

        >
          {drawer}
        </Drawer>
      </Box>


      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography>
          Ashok
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique unde
          fugit veniam eius, perspiciatis sunt? Corporis qui ducimus quibusdam,
          aliquam dolore excepturi quae. Distinctio enim at eligendi perferendis in
          cum quibusdam sed quae, accusantium et aperiam? Quod itaque exercitationem,
          at ab sequi qui modi delectus quia corrupti alias distinctio nostrum.
          Minima ex dolor modi inventore sapiente necessitatibus aliquam fuga et. Sed
          numquam quibusdam at officia sapiente porro maxime corrupti perspiciatis
          asperiores, exercitationem eius nostrum consequuntur iure aliquam itaque,
          assumenda et! Quibusdam temporibus beatae doloremque voluptatum doloribus
          soluta accusamus porro reprehenderit eos inventore facere, fugit, molestiae
          ab officiis illo voluptates recusandae. Vel dolor nobis eius, ratione atque
          soluta, aliquam fugit qui iste architecto perspiciatis. Nobis, voluptatem!
          Cumque, eligendi unde aliquid minus quis sit debitis obcaecati error,
          delectus quo eius exercitationem tempore. Delectus sapiente, provident
          corporis dolorum quibusdam aut beatae repellendus est labore quisquam
          praesentium repudiandae non vel laboriosam quo ab perferendis velit ipsa
          deleniti modi! Ipsam, illo quod. Nesciunt commodi nihil corrupti cum non
          fugiat praesentium doloremque architecto laborum aliquid. Quae, maxime
          recusandae? Eveniet dolore molestiae dicta blanditiis est expedita eius
          debitis cupiditate porro sed aspernatur quidem, repellat nihil quasi
          praesentium quia eos, quibusdam provident. Incidunt tempore vel placeat
          voluptate iure labore, repellendus beatae quia unde est aliquid dolor
          molestias libero. Reiciendis similique exercitationem consequatur, nobis
          placeat illo laudantium! Enim perferendis nulla soluta magni error,
          provident repellat similique cupiditate ipsam, et tempore cumque quod! Qui,
          iure suscipit tempora unde rerum autem saepe nisi vel cupiditate iusto.
          Illum, corrupti? Fugiat quidem accusantium nulla. Aliquid inventore commodi
          reprehenderit rerum reiciendis! Quidem alias repudiandae eaque eveniet
          cumque nihil aliquam in expedita, impedit quas ipsum nesciunt ipsa ullam
          consequuntur dignissimos numquam at nisi porro a, quaerat rem repellendus.
          Voluptates perspiciatis, in pariatur impedit, nam facilis libero dolorem
          dolores sunt inventore perferendis, aut sapiente modi nesciunt.
        </Typography>
      </Box> */}
    </Box>
    </>
  );  
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;


