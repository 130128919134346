
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import {Link} from 'react-router-dom';



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event: React. SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    let [portfolios,setPortfolios]=React.useState([]);
    let [cats,setCats]=React.useState([]);

    async function getPortfolioList() {
      fetch('https://cheapwebguru.com/blog/api/portfoliolist')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setCats(data.category);
        setPortfolios(data.objarr);

        // console.log("====<><>======>",data.objarr);
      });

      console.log("====<><>======>",portfolios.portfolio);
    }

    React.useEffect(()=>{
      getPortfolioList();
    },[]);
  
    return (

        <>

        <Grid className="innerHeader" py={5} textAlign="center">
             <Typography variant="h2" fontWeight="bold">Our  <Typography variant="body">Portfolio</Typography></Typography>
        </Grid>

        <section className="midWrapper">
            <Grid className="" pt={5} pb={5}>
                <Container>
                
                {/* <Box sx={{ width: '100%' }} textAlign="center">
                    <Box>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="Website Design and Development" {...a11yProps(0)} />
                        <Tab label="App Development" {...a11yProps(2)} />
                        <Tab label="Logo " {...a11yProps(1)} />
                    </Tabs>
                    </Box>

                    <TabPanel value={value} index={2}>
                   
                      <Container>
                          <Grid container className="logoBox" spacing={3} >
                              <Grid item sm={3} xs={6} ><img src="../../images/logo/logo1.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo2.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo3.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo4.png" alt="" /></Grid>

                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo5.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo6.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo7.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo8.png" alt="" /></Grid>

                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo9.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo10.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo11.png" alt="" /></Grid>
                              <Grid item sm={3} xs={6}><img src="../../images/logo/logo12.png" alt="" /></Grid>

                          </Grid>
                      </Container>

                    </TabPanel>


              
                    <TabPanel value={value} index={0}>
                    
                        <Container>
                        <Grid container className="wdBox" spacing={3} >
                            <Grid item sm={6} ><a href="https://hypekicks.in/" target="_blank"><img src="../../images/wb/wb12.jpg" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://shanviproperties.com/" target="_blank"><img src="../../images/wb/wb13.jpg" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://vasundharahotel.com/" target="_blank"><img src="../../images/wb/wb14.jpg" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://aarohanherbals.in/" target="_blank"><img src="../../images/wb/wb15.jpg" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://safalata.in/" target="_blank"><img src="../../images/wb/wb1.png" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://suvivah.org/" target="_blank"><img src="../../images/wb/wb2.png" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="#"><img src="../../images/wb/wb3.png" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://www.hooghlybawarchi.com/" target="_blank"><img src="../../images/wb/wb4.png" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://tarapithonline.com/" target="_blank"><img src="../../images/wb/wb5.png" alt="" /></a></Grid>
                            <Grid item sm={6} ><a href="https://angitia.co.in/" target="_blank"><img src="../../images/wb/wb6.png" alt="" /></a></Grid>
                            <Grid item sm={6} ><img src="../../images/wb/wb7.png" alt="" /></Grid>
                            <Grid item sm={6} ><img src="../../images/wb/wb8.png" alt="" /></Grid>
                            <Grid item sm={6} ><img src="../../images/wb/wb9.png" alt="" /></Grid>
                            <Grid item sm={6} ><img src="../../images/wb/wb10.png" alt="" /></Grid>
                            <Grid item sm={6} ><img src="../../images/wb/wb11.png" alt="" /></Grid>                            
                        </Grid>
                        </Container>
                    
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                    
                        <Container>
                        <Grid container className="wdBox" spacing={3} >
                            <Grid item sm={6} ><img src="../../images/app/app1.png" alt="" /></Grid>
                            <Grid item sm={6} ><img src="../../images/app/app2.png" alt="" /></Grid>
                            <Grid item sm={6} ><img src="../../images/app/app3.png" alt="" /></Grid>
                        </Grid>
                        </Container>

                    </TabPanel>

                </Box> */}

                <Box sx={{ width: '100%' }} textAlign="center">
                    <Box>
                    
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                      {cats.map((ct)=>
                        <Tab label={ct.portfolio_category_name} {...a11yProps(ct.portfolio_category_id)} key={ct.portfolio_category_id} />
                      )}
                    </Tabs>
                    
                    </Box>

                    {portfolios.map((e)=>
                    
                    <TabPanel value={value} index={e.portfolio_category_index} key={e.portfolio_category_id}>
                  
                      <Container>
                          <Grid container className="logoBox" spacing={3}>

                            {e.portfolio_category_id!=103?
                              e.portfolio.map((c)=>
                                <Grid item xs={6} key={c.portfolio_id}>
                                  {c.portfolio_link!=null?<a href={c.portfolio_link} target="_blank"><img src={"https://www.cheapwebguru.com/blog/upload_portfolio_image/"+c.portfolio_image_name} alt="" /></a>:<img src={"./upload_portfolio_image/"+c.portfolio_image_name} alt="" />}
                                </Grid>
                              )
                            :
                              e.portfolio.map((c)=>
                                <Grid item sm={3} xs={6} key={c.portfolio_id}>
                                  <img src={"https://www.cheapwebguru.com/blog/upload_portfolio_image/"+c.portfolio_image_name} alt="" />
                                </Grid>
                              )
                            }
                          </Grid>
                      </Container>

                    </TabPanel>
                    
                    )}

                </Box>

                
                </Container>
            </Grid>
        </section>





      

      </>
    );
  }
  

// function AboutContent() {

  
//     return(
//         <>


    
// <Grid className="innerHeader" py={5} textAlign="center">
//         <Typography variant="h2" fontWeight="bold">Our  <Typography variant="body">Portfolio</Typography></Typography>
// </Grid>
    

//    <section className="midWrapper">



//                <Grid className="" pt={5} pb={5}>
//                    <Container>
//                        <Grid container spacing={5}>


//                             <Grid item sm={12}>
//                                 <Grid container spacing={3}>
//                                     <Grid item xs={12}  textAlign="center">
//                                         <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Our Team</Typography>
//                                     </Grid>



//                                     <Grid item xs={12}>
                                        
                                        
//                                     <Box sx={{ width: '100%' }}>
//                                         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                                             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//                                             <Tab label="Item One" {...a11yProps(0)} />
//                                             <Tab label="Item Two" {...a11yProps(1)} />
//                                             <Tab label="Item Three" {...a11yProps(2)} />
//                                             </Tabs>
//                                         </Box>
//                                         <TabPanel value={value} index={0}>
//                                             Item One
//                                         </TabPanel>
//                                         <TabPanel value={value} index={1}>
//                                             Item Two
//                                         </TabPanel>
//                                         <TabPanel value={value} index={2}>
//                                             Item Three
//                                         </TabPanel>
//                                         </Box>


//                                     </Grid>


                                    
                                    
//                                 </Grid>
//                             </Grid>

                                                   
                           
//                    </Grid>
//                    </Container>
//                </Grid>


//                </section>
               

//         </>
//     );
// }

// export default AboutContent;