import React, {useState, useEffect} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import axios from "axios";
// axios.defaults.baseURL='https://web.cheapwebguru.com/angitia/api/';

function Testimonials() {

    // const [testimonialarr, setTestimonialarr] = useState([]);

    // async function gettestimonial() {
    //     var resp=await axios.get("/testimonial");
    //     var data=resp.data;
    //     setTestimonialarr(data.obj);
    //  }

    //  let imgpath="https://web.cheapwebguru.com/angitia/upload_testimonial/";

    useEffect(() => {
        // gettestimonial();
    }, [])



    const testimonial_carousel = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };



    return(
      <>

      {/* our testimonials start */}
    {/* <section className="py-5 testimonials_bg testimonials-row">
    <div className="container">
        <div className="row">
            <div className="text-center col-md-12">
                <header className="mb-5 w-50 m-auto">
                    <h4>Testimonials</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                </header>
            </div>
        </div>

        <div className="row">
            <div className="col-md-11 offse-md-1 m-auto">
            
            <Carousel responsive={testimonial_carousel} arrows={false} showDots={true} autoPlay={true} autoPlaySpeed={1500}>


            {testimonialarr.map((e)=>

                <div className="item"  key={e.id}>
                <div className="testimonial_box">
                    <h3>
                        <img src= {imgpath+e.image} alt="" className="testimonial_img" />                        
                        {e.name} -<span> {e.designation}</span></h3>
                    <p>{e.content}</p>
                </div>
                </div>


        )}




</Carousel>
            </div>               
        </div>
      </div>
</section> */}

            <Grid className="teamWrap" pt={5} pb={5}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6" p={0} textAlign="center">
                            {/* */}
                                <Grid item xs={12} textAlign="center">
                                {/* sx={{display: 'flex'}} */}
                                {/* <div className="text-center"> */}
                                <Typography variant="h6" p={0}><img src="images/icon.png" /></Typography>
                                <Typography variant="h6" p={0} sx={{paddingTop: '0px'}}>Our Client Review</Typography>
                                </Grid>
                                {/* </div> */}
                            </Typography>
                            <Grid item xs={12} textAlign="center" sx={{paddingTop: '20px'}}>
                            <Typography variant="h8" fontWeight="normal" pb={0}>We have established a record of happy clientele, as customer satisfaction is our topmost priority. Quality web designs and full-fledged coding with modern parameters is our strength. We are India’s best website development company with a 100% reliable team of experts in the field. It’s all about user experience at the end of the day, and we ensure that a website is made with all modern tools and ideas covering all the innovations and creativity of a top-notch website.</Typography>
                            {/* <Typography variant="body" color="primary">Expert Team</Typography> */}
                            </Grid>
                        </Grid>

                    
                        {/* <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/kaushik.jpg" width="100%" />
                           
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Kaushik Maitra
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Fullstack Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Ashok Mahato
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Frontend Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Mrinmoy Koley
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Laravel, Node JS Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Manish Mandal
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Website/App Designer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid> */}

                      

                      
                        
                </Grid>
                </Container>
            </Grid>
      
      
      

      
{/* our testimonials End */}





      </>
  );
}

export default Testimonials;